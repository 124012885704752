import PropTypes from 'prop-types';
import React from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { LazyIconCheckCircle } from '@/design-system/atoms/Icons/IconCheckCircle/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { Title } from '@/design-system/atoms/Title';
import Typography from '@/design-system/atoms/Typography';
import { MarketoForm } from '@/design-system/molecules/MarketoForm';

import styles from './NewsletterForm.module.scss';

const SuccessMessage = ({ message }) => {
    return message ? (
        <div className={styles['newsletter-form__success-message']}>
            <div className={styles['newsletter-form__success-message-content']}>
                <LazyIconCheckCircle size={'large'} />
                <Typography variant="t1" content={message} />
            </div>
        </div>
    ) : (
        <></>
    );
};

const NewsletterForm = ({
    headline,
    introText,
    image,
    marketoId,
    spanishId,
    munchkinAccountID,
    host,
    marketoServer,
    contentWidth,
    spanishDebugMode,
    successMessage,
    testMode,
    ...props
}) => {
    const isInset = contentWidth === 'inset';
    const analyticsObj = React.useMemo(() => {
        return {
            componentName: 'Newsletter Banner',
            componentTitle: headline,
            interactionType: 'cta',
        };
    }, [headline]);

    const successFragment = React.useMemo(() => {
        return <SuccessMessage message={successMessage} />;
    }, [successMessage]);

    return (
        <section submitSuccess>
            <ContentContainer isFullWidth={!isInset}>
                <div className={styles['newsletter-form__wrapper']}>
                    <ContentContainer
                        className={styles[`newsletter-form__inner--${contentWidth}`]}
                        isFullWidth={isInset}
                    >
                        <Grid
                            col={{ sm: 4, md: 6, lg: 12 }}
                            row={{ sm: 3, md: 3, lg: 2 }}
                            className={styles['newsletter-form__grid']}
                        >
                            <GridColumn
                                colSpan={{ sm: 4, md: 6, lg: 3 }}
                                colStart={{ sm: 1, md: 1, lg: 1 }}
                                rowSpan={{ sm: 1, md: 1, lg: 2 }}
                                rowStart={{ sm: 1, md: 1, lg: 1 }}
                                className={styles['newsletter-form__image-column']}
                            >
                                {image && (
                                    <div className={styles['newsletter-form__image-container']}>
                                        <Image
                                            {...image}
                                            mobileAspectRatio={Image.ASPECT_RATIO['1:1']}
                                            defaultAspectRatio={Image.ASPECT_RATIO['1:1']}
                                            roundedCorners={true}
                                        />
                                    </div>
                                )}
                            </GridColumn>
                            <GridColumn
                                colSpan={{ sm: 4, md: 6, lg: 6 }}
                                colStart={{ sm: 1, md: 1, lg: 4 }}
                                rowSpan={{ sm: 1, md: 1, lg: 1 }}
                                rowStart={{ sm: 2, md: 2, lg: 1 }}
                            >
                                <div className={styles['newsletter-form__content-container']}>
                                    {headline && (
                                        <Title
                                            className={styles[`newsletter-form__title`]}
                                            variant={Title.VARIANT.H4}
                                            tag={Title.TAG.H2}
                                            content={headline}
                                        />
                                    )}
                                    {introText && (
                                        <div className={styles['newsletter-form__description']}>
                                            <RichTextEditor text={introText} />
                                        </div>
                                    )}
                                </div>
                            </GridColumn>
                            <GridColumn
                                colSpan={{ sm: 4, md: 6, lg: 8 }}
                                colStart={{ sm: 1, md: 1, lg: 4 }}
                                rowSpan={{ sm: 1, md: 1, lg: 1 }}
                                rowStart={{ sm: 3, md: 3, lg: 2 }}
                            >
                                <div className={styles['newsletter-form__form-container']}>
                                    {marketoId && (
                                        <MarketoForm
                                            baseUrl={marketoServer}
                                            munchkinId={munchkinAccountID}
                                            formId={marketoId}
                                            spanishId={spanishId}
                                            spanishDebugMode={spanishDebugMode}
                                            formType={'newsletter'}
                                            successFragment={successFragment}
                                            analytics={analyticsObj}
                                            testMode={testMode}
                                        />
                                    )}
                                </div>
                            </GridColumn>
                        </Grid>
                    </ContentContainer>
                </div>
            </ContentContainer>
        </section>
    );
};
/**
 * Content width options
 */
NewsletterForm.CONTENT_WIDTH = {
    INSET: 'inset',
    FULL_WIDTH: 'fullWidth',
};

NewsletterForm.propTypes = {
    /**
     * title
     */
    headline: PropTypes.string,
    /**
     * description: html content
     */
    introText: PropTypes.string,
    /**
     * marketoId
     */
    marketoId: PropTypes.string,
    /**
     * spanishId
     */
    spanishId: PropTypes.string,
    /**
     * URL to the Marketo server instance
     */
    marketoServer: PropTypes.string,
    /**
     * Munchkin ID
     */
    munchkinAccountID: PropTypes.string,
    /**
     * host url
     */
    host: PropTypes.string,
    /**
     * success message
     */
    successMessage: PropTypes.string,
    /**
     * Width of the content in the grid
     */
    contentWidth: PropTypes.oneOf(['inset', 'fullWidth']),
};

export default NewsletterForm;
