import React, { createContext, useMemo } from 'react';

import window from '@/utils/window';

const defaultValue = {
    isSpanish: false,
};
const spanishHosts = ['es-ahs', 'espanol', 'es-prod'];

const LanguageContext = createContext(defaultValue);

export const LanguageProvider = ({ children, ...props }) => {
    const domain = window?.location?.host.split('.')[0];
    const value = useMemo(() => {
        let is_spanish = false;
        spanishHosts.forEach((host) => {
            if (host === domain) {
                is_spanish = true;
            }
        });
        return {
            isSpanish: is_spanish,
        };
    }, [domain]);
    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
export const useLanguageContext = () => {
    const context = React.useContext(LanguageContext);

    if (context == null) {
        throw new Error(`'useLanguageContext' needs to be within 'LanguageContext'`);
    }

    return context;
};
